var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "top": "0px",
      "left": "0px",
      "right": "0px",
      "bottom": "0px",
      "background-color": "white",
      "font-size": "14px",
      "font-family": "sans-serif",
      "overflow": "scroll"
    },
    attrs: {
      "id": "pdf"
    }
  }, [_c('div', {
    staticClass: "paper",
    staticStyle: {
      "width": "720px",
      "margin": "auto"
    }
  }, [_c('table', {
    staticClass: "table w-full text-left whitespace-no-wrap",
    attrs: {
      "id": "p-table"
    }
  }, [_c('thead', {
    staticClass: "bg-gray-200"
  }, [_c('tr', {
    staticClass: "text-left"
  }, [_c('th', {
    staticClass: "border p-2"
  }, [_vm._v(_vm._s(_vm._f("t")(_vm.$t("order_id"))))]), _c('th', {
    staticClass: "border p-1 text-center"
  }, [_vm._v(_vm._s(_vm.$t("seller")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("customer")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("phone")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("products")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("quantity")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("totalPrice")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("order_date")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('th', {
    staticClass: "border p-2 text-center"
  }, [_vm._v(_vm._s(_vm.$t("comment")))])])]), _c('tbody', [_c('tr', [_vm.items.length == 0 ? _c('th', {
    staticClass: "border p-3 bg-gray-100",
    attrs: {
      "colspan": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("t")("no_record_found")) + " ")]) : _vm._e()]), _vm._l(_vm.items, function (item, index) {
    return _c('tr', {
      key: index,
      staticClass: "text-center",
      class: index % 2 == 0 ? 'bg-gray-100' : ''
    }, [_c('td', {
      staticClass: "border p-2"
    }, [_c('span', {
      staticClass: "flex items-center"
    }, [_vm._v(" " + _vm._s(item.id) + " ")])]), _c('td', {
      staticClass: "border p-2"
    }, [_c('span', {
      staticClass: "flex items-center"
    }, [_vm._v(" " + _vm._s(item.seller && item.seller.fullName ? item.seller.fullName : "") + " ")])]), _c('td', {
      staticClass: "border p-2"
    }, [_c('span', {
      staticClass: "flex items-center"
    }, [_vm._v(" " + _vm._s(item.customer && item.customer.fullName ? item.customer.fullName : "") + " ")])]), _c('td', {
      staticClass: "border p-2"
    }, [_c('span', {
      staticClass: "flex items-center"
    }, [_vm._v(" " + _vm._s(item.customer && item.customer.phone ? item.customer.phone : "") + " ")])]), _c('td', {
      staticClass: "border p-2 bg-green-100"
    }, [_vm._v(" " + _vm._s(_vm.sumProduct(item.details)) + " ")]), _c('td', {
      staticClass: "border p-2 bg-green-100"
    }, [_vm._v(" " + _vm._s(_vm.sumQteProduct(item.details)) + " ")]), _c('td', {
      staticClass: "border p-2 bg-green-100"
    }, [_vm._v(" " + _vm._s(item.totalPrice) + " ")]), _c('td', {
      staticClass: "border p-2 bg-green-100"
    }, [_vm._v(" " + _vm._s(item.customer && item.customer.address ? item.customer.address : "") + " ")]), _c('td', {
      staticClass: "border p-2 bg-green-100"
    }, [_vm._v(" " + _vm._s(_vm.$moment(item.date).format("DD-MM-YYYY")) + " ")]), _c('td', {
      staticClass: "border p-2 bg-green-100"
    }, [_vm._v(" " + _vm._s(item.status.name) + " ")]), _c('td', {
      staticClass: "border p-2 bg-green-100"
    }, [_vm._v(" " + _vm._s(item.comment) + " ")])]);
  })], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }